
import { Options, Vue } from 'vue-class-component'
import { MarkerCluster, TMap } from '@map-component/vue-tmap'
import * as echarts from 'echarts'

import { useCache } from '@/hooks/web/useCache'
import { deviceListApi, deviceStatusApi, signalLevelApi } from '@/api/device'
import { getAlarmLogApi, loadLogApi, receiveAlarmApi, verifyAlarmApi } from '@/api/logs'
import { energyStatisticApi, getDevicesApi, monthAlarmAnalyseApi, yearAlarmAnalyseApi } from '@/api/elec'

import EnergyManage from '@/components/elec/EnergyManage.vue'
import EnergyAnalyse from '@/components/elec/EnergyAnalyse.vue'
import UserInfo from '@/components/elec/UserInfo.vue'
import RepairReport from '@/components/elec/RepairReport.vue'
import ParamSetting from '@/components/elec/ParamSetting.vue'
import DeviceStatus from '@/components/elec/DeviceStatus.vue'
import XYTree from '@/components/widgets/XYTree.vue'
import { repairStatisticApi } from '@/api/repair'
import {checkLoginApi} from "@/api/auth";

interface Tree {
  [key: string]: any
}

@Options({
  components: {
    XYTree,
    DeviceStatus,
    ParamSetting,
    RepairReport,
    UserInfo,
    MarkerCluster,
    TMap,
    EnergyManage,
    EnergyAnalyse
  },
  data () {
    return {
      openType: 'login',
      wsCache: null,
      name: '',
      seatName: '',
      token: '',
      adminId: '0',
      isPolice: '0',
      isManager: '0',
      timeInterval: null,
      timeStr: '',
      mouseEnter: false,
      expandAll: false,
      selectedDeviceType: 'elec_alarm',
      filterText: '',
      defaultProps: {
        value: 'nodeId',
        label: 'label',
        children: 'children'
      },
      tempDeviceData: [],
      devicesData: [],
      usersData: [],
      innerWidth: 0,
      innerHeight: 0,
      alarmAudioSrc: require('../assets/audios/alarm.mp3'),
      repairStatistic: {
        checkingCnt: 0,
        evaluatingCnt: 0,
        finishedCnt: 0,
        handlingCnt: 0,
        totalCnt: 0,
        waitingCnt: 0
      },
      deviceStatus: {
        totalCnt: 0,
        onlineCnt: 0,
        offlineCnt: 0,
        brokenCnt: 0,
        alarmCnt: 0
      },
      energyStatistic: {
        dayCompare: 0,
        lastMonth: '',
        monthCompare: 0,
        thisMonth: '',
        today: '',
        total: '',
        yesterday: ''
      },
      alarmInfoData: [],
      selectedMonth: '',
      selectedUser: {
      },
      handlingLog: {},
      handlingStatus: false,
      showHistoryLog: false,
      historyLog: {},
      showSelectedUser: false,
      showAlarmInfo: false,
      id: 'cluster',
      enableDefaultStyle: true,
      minimumClusterSize: 2,
      zoomOnClick: true,
      gridSize: 10,
      averageCenter: false,
      maxZoom: 10,
      center: { lat: 40.115935, lng: 124.362676 },
      geometries: [],
      value1: '',
      value2: '',
      activeTab: 'map',
      selectedHostNo: '',
      selectedServiceCenter: 0,
      selectedServiceCenterLevel: 0,
      showDeviceType: 'all',
      page: 1,
      total: 0,
      lastPage: 0,
      perPage: 0,
      devices: [],
      pageNum: 0,
      activeItem: {
        host_no: '',
        device_no: '',
        device_type: ''
      },
      ws: null,
      wsInterval: null,
      alarmFormRules: {
        verifyMethod: [{
          required: true,
          message: '请选择核警方式',
          trigger: 'change'
        }],
        verifyRecord: [{
          required: true,
          message: '请输入备注内容',
          trigger: 'blur'
        }]
      },
      alarmFormData: {
        verifyMethod: '',
        verifyRecord: '',
        handleRecord: ''
      },
      historyAlarmFormData: {
        verifyMethod: '',
        verifyRecord: '',
        handleRecord: ''
      },
      refreshInterval: null,
      checkLoginInterval: null
    }
  },
  mounted () {
    const self = this

    if (this.$route.query.open_type !== undefined) {
      this.openType = this.$route.query.open_type
    }

    const { wsCache } = useCache()

    this.wsCache = wsCache

    this.name = wsCache.get('name')
    this.seatName = wsCache.get('seat_name')
    this.token = wsCache.get('token')
    this.adminId = wsCache.get('admin_id').toString()
    this.isPolice = wsCache.get('is_police').toString()
    this.isManager = wsCache.get('is_manager')

    if (this.timeInterval !== null) {
      clearInterval(this.timeInterval)
    }

    this.setTimeStr()
    this.timeInterval = setInterval(this.setTimeStr, 1000)

    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight

    window.addEventListener('resize', this.resetSize)

    this.$nextTick(function () {
      self.signalLevelChart = echarts.init(document.getElementById('signalLevelChartBox'))
      self.monthAlarmChart = echarts.init(document.getElementById('monthAlarmChartBox'))
      self.yearAlarmAnalyseChart = echarts.init(document.getElementById('yearAlarmAnalyseBox'))

      self.loadEnergyStatisticData()
      self.loadYearAlarmAnalyseData()
      self.loadSignalLevelChart()
      self.loadMonthAlarmAnalyse()
    })

    this.loadDeviceList()
    this.loadRepairStatistic()
    this.loadDeviceStatus()
    this.loadAlarmLogs()

    this.startWebSocket()

    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval)
    }

    this.refreshInterval = setInterval(function () {
      self.loadEnergyStatisticData()
      self.loadYearAlarmAnalyseData()
      self.loadSignalLevelChart()
      self.loadMonthAlarmAnalyse()
      self.loadRepairStatistic()
    }, 600000)

    if (this.checkLoginInterval !== null) {
      clearInterval(this.checkLoginInterval)
    }

    this.checkLoginInterval = setInterval(function () {
      self.checkLogin()
    }, 60000)
  },
  unmounted () {
    if (this.timeInterval !== null) {
      clearInterval(this.timeInterval)
    }

    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval)
    }

    if (this.checkLoginInterval !== null) {
      clearInterval(this.checkLoginInterval)
    }

    this.signalLevelChart.dispose()
    this.monthAlarmChart.dispose()
    this.yearAlarmAnalyseChart.dispose()

    window.removeEventListener('resize', this.resetSize)
  },
  computed: {
    mapStyl () {
      const res = {
        display: 'block'
      }

      if (this.activeTab !== 'map') {
        res.display = 'none'
      }

      return res
    },
    deviceBoxStyl () {
      if (this.activeTab === 'map') {
        return {
          display: 'none'
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    setTimeStr: function () {
      let str = ''

      const d = new Date()

      str += d.getFullYear()
      str += '-'
      str += d.getMonth() >= 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
      str += '-'
      str += d.getDate() >= 10 ? d.getDate() : '0' + d.getDate()
      str += ' '
      str += d.getHours() >= 10 ? d.getHours() : '0' + d.getHours()
      str += ':'
      str += d.getMinutes() >= 10 ? d.getMinutes() : '0' + d.getMinutes()
      str += ':'
      str += d.getSeconds() >= 10 ? d.getSeconds() : '0' + d.getSeconds()
      str += ' '

      str += ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][d.getDay()]

      this.timeStr = str
    },
    initMonth: function () {
      let str = ''

      const d = new Date()

      str += d.getFullYear()
      str += '-'
      str += d.getMonth() >= 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)

      this.selectedMonth = str
    },
    checkLogin: function () {
      checkLoginApi({
        jwt: this.token
      }).then((res: any) => {
        if (res.data.flag === false) {
          this.wsCache.delete('name')
          this.wsCache.delete('token')
          this.wsCache.delete('admin_id')
          this.wsCache.delete('is_police')
          this.wsCache.delete('is_manager')

          this.$router.replace({name: 'Login'})
        }
      })
    },
    setting: function () {
      if (window.newWindow !== undefined) {
        window.newWindow({
          width: 800,
          height: 500,
          title: '系统设置',
          resizable: false,
          url: 'setting'
        })
      } else {
        this.$router.replace({ name: 'Setting' })
      }
    },
    toggleFullScreen: function () {
      const isFullscreen = this.wsCache.get('is_fullscreen')

      if (isFullscreen === true) {
        document.exitFullscreen()

        this.wsCache.set('is_fullscreen', false)
      } else {
        const ele = document.getElementById('app');

        if (ele !== null) {
          ele.requestFullscreen()

          this.wsCache.set('is_fullscreen', true)
        }
      }
    },
    doLogout: function () {
      if (this.openType === 'login') {
        this.wsCache.delete('name')
        this.wsCache.delete('token')
        this.wsCache.delete('admin_id')
        this.wsCache.delete('is_police')
        this.wsCache.delete('is_manager')

        this.$router.replace({name: 'Login'})
      } else {
        this.$router.replace({name: 'Main'})
      }
    },
    startWebSocket () {
      const self = this

      if (this.wsInterval !== null) {
        clearInterval(this.wsInterval)

        this.wsInterval = null
      }

      this.ws = new WebSocket('//web-pc.bonqua.cn/wss')

      this.ws.onclose = function () {
        clearInterval(self.wsInterval)

        self.wsInterval = null
        self.ws = null
      }

      this.ws.onopen = function () {
        const data = JSON.stringify({
          key: 'user_login',
          token: self.token
        }) + '\n'

        self.ws.send(data)

        self.wsInterval = setInterval(() => {
          if (self.ws !== null) {
            const data = JSON.stringify({
              key: 'heartbeat',
              token: self.token
            }) + '\n'

            self.ws.send(data)
          }
        }, 30000)
      }

      this.ws.onerror = function (e: any) {
        clearInterval(self.wsInterval)

        self.wsInterval = null
        self.ws = null

        console.log(e)
      }

      this.ws.onmessage = function (data: any) {
        const msg = JSON.parse(data.data)

        if (msg.msg_type === 'log_msg') {
          // 此处仅处理智慧用电设备的警情
          if (msg.host_type !== '电警报警设备') {
            return
          }

          if (self.$refs.deviceStatus !== undefined) {
            self.$refs.deviceStatus.receivedMessage(msg)
          }

          if (msg.is_alarm === 1 || msg.is_alarm === '1') {
            const flag = self.hasAlarmInfo(msg)

            if (flag) {
              return
            }

            if (msg.alarm_log_type === 'resend' && self.handlingLog.log_id === msg.log_id) {
              return
            }

            if (msg.log_status === undefined) {
              msg.log_status = '0'
              msg.log_status_txt = '未处理'
            } else {
              msg.log_status = msg.log_status + ''
            }

            self.alarmInfoData.unshift(msg)

            if (self.alarmInfoData.length > 15) {
              self.alarmInfoData.pop()
            }

            if (self.isPolice === '1' && msg.admin_id === self.adminId) {
              if (!self.handlingStatus) {
                self.loadAlarmLog(msg.log_id)
              }
            }
          }
        } else if (msg.msg_type === 'receive_alarm') {
          const tmp = JSON.parse(JSON.stringify(self.alarmInfoData))

          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].log_id === (msg.log_id + '')) {
              tmp[i].log_status_txt = msg.log_status_txt
              tmp[i].log_status = msg.log_status
              tmp[i].worker_name = msg.worker_name

              break
            }
          }

          self.alarmInfoData = tmp

          if (!self.handlingStatus && self.handlingLog.log_id === msg.log_id) {
            self.showAlarmInfo = false
          }
        } else if (msg.msg_type === 'verify_alarm') {
          const tmp = JSON.parse(JSON.stringify(self.alarmInfoData))

          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].log_id === (msg.log_id + '')) {
              tmp[i].log_status_txt = msg.log_status_txt
              tmp[i].log_status = msg.log_status
              tmp[i].worker_name = msg.worker_name

              break
            }
          }

          self.alarmInfoData = tmp

          if (!self.handlingStatus && self.handlingLog.log_id === msg.log_id) {
            self.showAlarmInfo = false
          }
        }
      }
    },
    stopWebsocket () {
      if (this.wsInterval !== null) {
        clearInterval(this.wsInterval)
      }

      this.wsInterval = null
      this.ws = null
    },
    hasAlarmInfo (msg: any) {
      for (let i = 0; i < this.alarmInfoData.length; i++) {
        if (this.alarmInfoData[i].log_id === msg.log_id) {
          return true
        }
      }

      return false
    },
    mouseEnterDeviceArea: function () {
      this.mouseEnter = true
    },
    mouseLeaveDeviceArea: function () {
      this.mouseEnter = false
    },
    toggleCheckDeviceType: function () {
      this.showCheckDeviceTypeBox = !this.showCheckDeviceTypeBox
    },
    toggleDeviceTree: function () {
      this.showDeviceTree = !this.showDeviceTree
    },
    resetSize: function () {
      const self = this

      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight

      this.$nextTick(() => {
        self.monthAlarmAnaliseChart.resize()
        self.yearAlarmAnalyseChart.resize()
        self.signalLevelChart.resize()
        self.monthAlarmChart.resize()
      })
    },
    switchTab: function (tab: string) {
      this.activeTab = tab
    },
    loadRepairStatistic: function () {
      repairStatisticApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.repairStatistic.checkingCnt = res.checking
        this.repairStatistic.evaluatingCnt = res.evaluating
        this.repairStatistic.finishedCnt = res.finished
        this.repairStatistic.handlingCnt = res.handling
        this.repairStatistic.totalCnt = res.total
        this.repairStatistic.waitingCnt = res.waiting
      })
    },
    loadDeviceStatus: function () {
      deviceStatusApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.deviceStatus.totalCnt = res.total_cnt
        this.deviceStatus.onlineCnt = res.online_cnt
        this.deviceStatus.offlineCnt = res.offline_cnt
        this.deviceStatus.brokenCnt = res.broken_cnt
        this.deviceStatus.alarmCnt = res.alarm_cnt
      })
    },
    loadDeviceList: function () {
      deviceListApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initDeviceTreeData(res, '')

        this.tempDeviceData = JSON.parse(JSON.stringify(res))
      })
    },
    initDeviceTreeData: function (res: any, filter: string) {
      const serviceCenterId = res.service_center_id
      const serviceCenters = res.service_centers
      const users = res.users
      const devices = res.devices

      this.usersData = users

      const tmp: any = {}

      const now = (new Date()).getTime()

      devices.forEach((item: any) => {
        item.label = item.name === null ? item.host_no + '_' + item.device_no : item.name
        item.node_type = 'device'
        item.device_type = 'elec_alarm'
        item.nodeId = 'd_' + item.host_no + '_' + item.device_no

        if (filter !== '' && item.label.indexOf(filter) < 0) {
          return
        }

        const lastMsgAt = (new Date(item.last_msg_at)).getTime()

        if (now - lastMsgAt > 24 * 3600 * 1000) {
          item.online_status = '0'
        } else {
          item.online_status = '1'
        }

        if (tmp['device_' + item.host_no] === undefined) {
          tmp['device_' + item.host_no] = []
        }

        tmp['device_' + item.host_no].push(item)
      })

      this.geometries = []

      users.forEach((item: any) => {
        if (item.service_center_level_4 === '0') {
          return
        }

        item.name = item.name === null || item.name === '' ? item.host_no : item.name
        item.label = '[' + item.host_no + ']' + item.name
        item.node_type = 'user'
        item.device_type = 'elec_alarm'
        item.nodeId = 'u_' + item.host_no

        if (tmp['device_' + item.host_no] !== undefined && tmp['device_' + item.host_no].length > 0) {
          item.children = tmp['device_' + item.host_no]
        } else {
          item.children = []

          if (filter !== '' && item.label.indexOf(filter) < 0) {
            return
          }
        }

        if (tmp['service_center_' + item.service_center_level_4] === undefined) {
          tmp['service_center_' + item.service_center_level_4] = []
        }

        tmp['service_center_' + item.service_center_level_4].push(item)

        this.geometries.push({
          position: { lat: item.latitude, lng: item.longitude }
        })
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '4') {
          item.label = item.name
          item.nodeId = 's_' + item.id

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
          } else {
            item.children = []

            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '3') {
          item.label = item.name
          item.nodeId = 's_' + item.id

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
          } else {
            item.children = []

            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '2') {
          item.label = item.name
          item.nodeId = 's_' + item.id

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
          } else {
            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      serviceCenters.forEach((item: any) => {
        if (item.level === '1') {
          item.label = item.name
          item.nodeId = 's_' + item.id

          if (tmp['service_center_' + item.id] !== undefined) {
            item.children = tmp['service_center_' + item.id]
          } else {
            if (filter !== '' && item.label.indexOf(filter) < 0) {
              return
            }
          }

          if (tmp['service_center_' + item.parent_id] === undefined) {
            tmp['service_center_' + item.parent_id] = []
          }

          tmp['service_center_' + item.parent_id].push(item)
        }
      })

      this.devicesData = tmp['service_center_' + serviceCenterId] === undefined ? [] : tmp['service_center_' + serviceCenterId]
    },
    callFilterNode: function () {
      const tmp = JSON.parse(JSON.stringify(this.tempDeviceData))

      this.initDeviceTreeData(tmp, this.filterText.trim())

      this.expandAll = this.filterText.trim() !== ''
    },
    treeNodeClick: function (nodeId: string, node: any) {
      /*
      if (node.node_type === 'user') {
        this.selectedUser = JSON.parse(JSON.stringify(node))
        this.showSelectedUser = true
      } else if (node.node_type === 'device') {
        for (let i = 0; i < this.usersData.length; i++) {
          if (this.usersData[i].host_no === node.host_no) {
            this.selectedUser = JSON.parse(JSON.stringify(this.usersData[i]))
            this.selectedUser.online_status = node.online_status
            this.selectedUser.csq = node.csq
            this.selectedUser.battery_voltage = node.battery_voltage

            this.showSelectedUser = true

            break
          }
        }
      } else {
      }
       */

      this.activeTab = 'device_list'
      this.selectedDeviceType = 'all'
      this.selectedServiceCenter = node.id
      this.selectedServiceCenterLevel = node.level
      this.selectedHostNo = node.host_no

      this.loadDevices()
    },
    hideHistoryAlarmInfo () {
      this.showHistoryLog = false
    },
    loadDevices: function () {
      getDevicesApi({
        token: this.token,
        host_no: this.selectedHostNo,
        host_type: 'elec_alarm',
        service_center: this.selectedServiceCenter,
        service_center_level: this.selectedServiceCenterLevel,
        type: this.showDeviceType,
        page: this.page
      }).then((res: any) => {
        const devices: any = res.devices

        this.page = devices.current_page
        this.total = devices.total
        this.lastPage = devices.last_page
        this.perPage = devices.perPage
        this.devices = devices.data
      })
    },
    gotoFirstPage: function () {
      if (this.page !== 1) {
        this.page = 1

        this.loadDevices()
      }
    },
    gotoPrevPage: function () {
      if (this.page > 1) {
        this.page -= 1

        this.loadDevices()
      }
    },
    gotoNextPage: function () {
      if (this.page < this.lastPage) {
        this.page += 1

        this.loadDevices()
      }
    },
    gotoLastPage: function () {
      if (this.page !== this.lastPage) {
        this.page = this.lastPage

        this.loadDevices()
      }
    },
    gotoPage: function () {
      if (this.pageNum > this.lastPage) {
        this.pageNum = this.lastPage
      }

      if (this.pageNum < 1) {
        this.pageNum = 1
      }

      if (this.pageNum !== this.page) {
        this.page = this.pageNum

        this.loadDevices()
      }
    },
    switchDeviceType (type: string) {
      if (this.showDeviceType !== type) {
        this.showDeviceType = type

        this.loadDevices()
      }
    },
    checkDeviceStatus (item: any) {
      return item.transient_short_circuit_alarm === '1' ||
        item.electric_leakage_alarm === '1' ||
        item.arc_discharge_alarm === '1' ||
        item.short_time_delay_short_circuit_alarm === '1' ||
        item.overload_alarm === '1' ||
        item.overvoltage_alarm === '1' ||
        item.undervoltage_alarm === '1' ||
        item.temperature_alarm === '1' ||
        item.transient_short_circuit_fault === '1' ||
        item.electric_leakage_fault === '1' ||
        item.arc_discharge_fault === '1' ||
        item.short_time_delay_short_circuit_fault === '1' ||
        item.overload_fault === '1' ||
        item.overvoltage_fault === '1' ||
        item.undervoltage_fault === '1' ||
        item.temperature_fault === '1'
    },
    deviceStyl (item: any) {
      if (item.online_status !== '1') {
        return {
          borderColor: '#a0a0a1'
        }
      }

      if (item.transient_short_circuit_fault === '1' ||
        item.electric_leakage_fault === '1' ||
        item.arc_discharge_fault === '1' ||
        item.short_time_delay_short_circuit_fault === '1' ||
        item.overload_fault === '1' ||
        item.overvoltage_fault === '1' ||
        item.undervoltage_fault === '1' ||
        item.temperature_fault === '1') {
        return {
          borderColor: '#e50112'
        }
      }

      return {
        borderColor: '#04d7c4'
      }
    },
    deviceItemClicked (item: any) {
      this.activeItem = item

      this.activeTab = 'device_status'
    },
    hideSelectedUser: function () {
      this.selectedUser = {}
      this.showSelectedUser = false
    },
    loadAlarmLogs: function () {
      loadLogApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.alarmInfoData = res.logs
      })
    },
    loadEnergyStatisticData: function () {
      energyStatisticApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.energyStatistic.dayCompare = res.day_compare
        this.energyStatistic.lastMonth = res.last_month
        this.energyStatistic.monthCompare = res.month_compare
        this.energyStatistic.thisMonth = res.this_month
        this.energyStatistic.today = res.today
        this.energyStatistic.total = res.total
        this.energyStatistic.yesterday = res.yesterday
      })
    },
    loadYearAlarmAnalyseData: function () {
      yearAlarmAnalyseApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initYearAlarmAnalyseChart(res)
      })
    },
    loadSignalLevelChart: function () {
      signalLevelApi({
        token: this.token,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initSignalLevelChart(res)
      })
    },
    loadMonthAlarmAnalyse: function () {
      monthAlarmAnalyseApi({
        token: this.token,
        month: this.selectedMonth,
        host_type: this.selectedDeviceType
      }).then((res: any) => {
        this.initMonthAlarmChart(res)
      })
    },
    initMonthAlarmAnaliseChart: function (res: any) {
      const dateArr: string[] = res.alarm.reduce((t: string[], item: any) => {
        t.push(item.date)

        return t
      }, [])

      const offlineCntArr: number[] = res.offline.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const alarmCntArr: number[] = res.alarm.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const brokenCntArr: number[] = res.broken.reduce((t: number[], item: any) => {
        t.push(item.cnt)

        return t
      }, [])

      const option = {
        xAxis: {
          type: 'category',
          data: dateArr,
          axisLine: {
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#828fb1'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#fff'
            },
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: ['#828fb1']
            }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{c} '
        },
        legend: {
          top: 0,
          left: '4%',
          itemWidth: 16,
          itemHeight: 12,
          icon: 'roundRect',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '40px',
          containLabel: true,
          color: '#fff'
        },
        series: [{
          name: '设备离线',
          data: offlineCntArr,
          type: 'line',
          color: '#09954f'
        },
        {
          name: '设备报警',
          data: alarmCntArr,
          type: 'line',
          color: '#ffda67'
        },
        {
          name: '设备故障',
          data: brokenCntArr,
          type: 'line',
          color: '#ed5f6e'
        }]
      }

      this.monthAlarmAnaliseChart.setOption(option)
    },
    initYearAlarmAnalyseChart: function (res: any) {
      const dataX = []
      const data = []

      for (let i = 0; i < res.alarm.length; i++) {
        dataX[i] = res.alarm[i].date
        data[i] = res.alarm[i].cnt - 0
      }

      const option = {
        title: {
          show: false
        },
        grid: {
          left: '15%',
          top: '5%',
          bottom: '15%',
          right: '5%'
        },
        xAxis: {
          data: dataX,
          axisLabel: {
            inside: false,
            textStyle: {
              color: '#fff',
              fontSize: '8'
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#094f99'
            }
          },
          z: 10
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: '#094f99'
            }
          },
          axisTick: {
            show: true
          },
          axisLabel: {
            textStyle: {
              color: '#ffffff'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#094f99'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#4cf0f9' },
                  { offset: 0.5, color: '#2ccbe5' },
                  { offset: 1, color: '#0097c8' }
                ]
              )
            },
            data: data
          }
        ]
      }

      this.yearAlarmAnalyseChart.setOption(option)
    },
    initSignalLevelChart: function (res: any) {
      const option = {
        legend: {
          top: 20,
          orient: 'vertical',
          left: 'right',
          align: 'left',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: 'white'
          },
          icon: 'circle'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '60%',
            center: ['40%', '50%'],
            label: {
              color: 'white',
              alignTo: 'labelLine'
            },
            minAngle: 3,
            data: [
              { value: res.high, name: '信号强 ' + res.high },
              { value: res.middle, name: '信号中 ' + res.middle },
              { value: res.low, name: '信号弱 ' + res.low },
              { value: res.no_signal, name: '无信号 ' + res.no_signal }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      this.signalLevelChart.setOption(option)
    },
    initMonthAlarmChart: function (param: any) {
      const shortCircuitCnt = param.short_circuit_cnt - 0
      const leakageCnt = param.leakage_cnt - 0
      const overVoltageCnt = param.over_voltage_cnt - 0
      const underVoltageCnt = param.under_voltage_cnt - 0
      const currentAlarmCnt = param.current_alarm_cnt - 0
      const temperateAlarmCnt = param.temperate_alarm_cnt - 0
      const arcAlarmCnt = param.arc_alarm_cnt - 0

      const total = shortCircuitCnt + leakageCnt + overVoltageCnt + underVoltageCnt + currentAlarmCnt + temperateAlarmCnt + arcAlarmCnt

      const option = {
        title: {
          text: total,
          subtext: '报警总数',
          left: 'center',
          top: 'center',
          itemGap: 5,
          textStyle: {
            color: 'white',
            fontSize: 20
          },
          subtextStyle: {
            fontSize: 14,
            color: 'white'
          }
        },
        legend: {
          top: 10,
          orient: 'vertical',
          left: 'left',
          align: 'left',
          textStyle: {
            color: 'white'
          },
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10
        },
        label: {
          show: false,
          fontSize: 12,
          color: 'transparent'
        },
        labelLine: {
          show: false,
          lineStyle: {
            color: 'red'
          },
          opacity: 0
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: shortCircuitCnt, name: '短路报警 ' + shortCircuitCnt + '(' + (shortCircuitCnt * 100 / total).toFixed(1) + '%)' },
              { value: leakageCnt, name: '漏电报警 ' + leakageCnt + '(' + (leakageCnt * 100 / total).toFixed(1) + '%)' },
              { value: overVoltageCnt, name: '过压报警 ' + overVoltageCnt + '(' + (overVoltageCnt * 100 / total).toFixed(1) + '%)' },
              { value: underVoltageCnt, name: '欠压报警 ' + underVoltageCnt + '(' + (underVoltageCnt * 100 / total).toFixed(1) + '%)' },
              { value: currentAlarmCnt, name: '过流报警 ' + currentAlarmCnt + '(' + (currentAlarmCnt * 100 / total).toFixed(1) + '%)' },
              { value: temperateAlarmCnt, name: '温度报警 ' + temperateAlarmCnt + '(' + (temperateAlarmCnt * 100 / total).toFixed(1) + '%)' },
              { value: arcAlarmCnt, name: '拉弧报警 ' + arcAlarmCnt + '(' + (arcAlarmCnt * 100 / total).toFixed(1) + '%)' }
            ]
          }
        ]
      }

      this.monthAlarmChart.setOption(option)
    },
    handleRowClick (data: any) {
      this.loadAlarmLog(data.log_id)
    },
    loadAlarmLog (logId: string, type: string) {
      getAlarmLogApi({
        token: this.token,
        log_id: logId
      }).then((res: any) => {
        if (type === 'history') {
          this.showHistoryLog = true
          this.historyLog = res.log_data

          this.historyAlarmFormData = {
            verifyMethod: res.log_data.verify_method == '0' ? '': res.log_data.verify_method,
            verifyRecord: res.log_data.verify_record,
            handleRecord: res.log_data.handle_record
          }
        } else {
          this.showAlarmInfo = true
          this.handlingLog = res.log_data

          if (res.log_data !== undefined) {
            this.center = {
              lat: res.log_data.latitude,
              lng: res.log_data.longitude
            }
          }

          this.alarmFormData = {
            verifyMethod: res.log_data.verify_method == '0' ? '': res.log.verify_method,
            verifyRecord: res.log_data.verify_record,
            handleRecord: res.log_data.handle_record
          }

          this.$refs.alarmAudio.play()
        }
      })
    },
    receiveAlarm (type: string) {
      let log_id = ''

      if (type === 'history') {
        log_id = this.historyLog.log_id
      } else {
        log_id = this.handlingLog.log_id
      }

      receiveAlarmApi({
        token: this.token,
        log_id: log_id
      }).then((res: any) => {
        if (type === 'history') {
          this.historyLog.log_status = res.log_status
          this.handlingStatus = true
        } else {
          this.handlingLog.log_status = res.log_status

          this.$refs.alarmAudio.stop()
        }
      })
    },
    verifyAlarmInfo () {
      this.$refs.handleAlarmForm.validate((valid: boolean) => {
        if (valid) {
          verifyAlarmApi({
            token: this.token,
            log_id: this.handlingLog.log_id,
            verify_method: this.alarmFormData.verifyMethod,
            verify_record: this.alarmFormData.verifyRecord,
            handle_record: this.alarmFormData.handleRecord
          }).then((res: any) => {
            this.showAlarmInfo = false
            this.handlingStatus = false
          })
        }
      })
    },
    hideAlarmInfo () {
      this.showAlarmInfo = false
    },
    verifyHistoryAlarmInfo () {
      this.$refs.handleHistoryAlarmForm.validate((valid: boolean) => {
        if (valid) {
          verifyAlarmApi({
            token: this.token,
            log_id: this.historyLog.log_id,
            verify_method: this.historyAlarmFormData.verifyMethod,
            verify_record: this.historyAlarmFormData.verifyRecord,
            handle_record: this.historyAlarmFormData.handleRecord
          }).then((res: any) => {
            this.showHistoryLog = false
          })
        }
      })
    }
  }
})
export default class ElecAlarm extends Vue {}
